import React, { useState, useEffect } from "react";
import Header from "../header/header";
import Main from "../main/main";
import ChatApp from "../chat/chat2";
import Loading from "../load/load";
import Banner from "../baner/baner";
import Blog from "../blog/blog";
import Footer from "../footer/footer";

const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // بعد از 8 ثانیه لودینگ خاموش می‌شود
    setTimeout(() => {
      setLoading(false);
    }, 8000); // تنظیم مدت زمان ۸ ثانیه
  }, []);

  return (
    <>
      {loading ? (
        <Loading /> // نمایش صفحه مشکی به مدت ۸ ثانیه
      ) : (
        <>
          <div className="header h-[50px] md:h-[100px]">
            <Header />
          </div>
          <Main />
          <Banner />
          <Blog />
          <ChatApp />
          <Footer />
        </>
      )}
    </>
  );
};

export default Home;