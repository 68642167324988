import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ManageDates from "./ManageDates"; // اضافه کردن کامپوننت مدیریت تاریخ
import AddTime from './addtime';
import ChatboxEditor from "./chatboxeditor";
import UserInfo from "./userinfo";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import BlogManager from "./blogManegment"
import FooterManager from "./footerManegment";;
const Panel = ({ onLogout }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activePage, setActivePage] = useState("account"); // پیش‌فرض صفحه "حساب کاربری"
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // state برای کنترل نمایش sidebar
  const navigate = useNavigate();

  useEffect(() => {
    const storedUsername = localStorage.getItem("username");

    if (!storedUsername) {
      navigate("/login");
    } else {
      const fetchUserData = async () => {
        try {
          const response = await axios.get(`https://api.asmary.com/data_admin/info/${storedUsername}`);
          setUserData(response.data);
        } catch (error) {
          setError("Unable to fetch data. Please try again later.");
        } finally {
          setLoading(false);
        }
      };

      fetchUserData();
    }
  }, [navigate]);

  const handleLogout = () => {
    onLogout(); // Call the logout function from props
  };

  // انیمیشن صفحه منو
  const handleMenuClick = (page) => {
    setActivePage(page); // تنظیم صفحه فعال
    setIsSidebarOpen(false); // بستن sidebar بعد از انتخاب
  };

  if (loading) {
    return <div>در حال بارگذاری...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="flex h-screen" dir="rtl">
      {/* Sidebar */}
      <div className={`fixed inset-0 bg-[#3864F2] text-white p-6 pl-10 space-y-4 transform transition-transform duration-500 ease-in-out ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"} md:translate-x-0 md:relative md:w-64`}>
        <h2 className="text-2xl font-semibold mb-4">پنل مدیریتی</h2>
        <ul className="space-y-2 pr-5">
          {/* منوی حساب کاربری */}
          <li
            onClick={() => handleMenuClick("account")}
            className={`p-3 cursor-pointer transition-all transform ${activePage === "account" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
          >
            حساب کاربری
          </li>
          {/* منوی مدیریت تاریخ */}
          <li
            onClick={() => handleMenuClick("manageDates")}
            className={`p-3 cursor-pointer transition-all transform ${activePage === "manageDates" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
          >
            مدیریت تاریخ
          </li>
          {/* منوی تنظیمات */}
          <li
            onClick={() => handleMenuClick("settings")}
            className={`p-3 cursor-pointer transition-all transform ${activePage === "settings" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
          >
            تنظیمات زمانی
          </li>
          {/* منوی گزارش‌ها */}
          <li
            onClick={() => handleMenuClick("reports")}
            className={`p-3 cursor-pointer transition-all transform ${activePage === "reports" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
          >
            تنظیمات پیامک ها
          </li>
          <li
            onClick={() => handleMenuClick("blog")}
            className={`p-3 cursor-pointer transition-all transform ${activePage === "blog" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
          >
            تنظیمات بلاگ
          </li>
          <li
            onClick={() => handleMenuClick("footer")}
            className={`p-3 cursor-pointer transition-all transform ${activePage === "footer" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
          >
            تنظیمات فوتر
          </li>
          {/* منوی پشتیبانی */}
          <li
            onClick={() => handleMenuClick("support")}
            className={`p-3 cursor-pointer transition-all transform ${activePage === "support" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
          >
            تایم های رزرو شده
          </li>
        </ul>
      </div>

      {/* Button to toggle sidebar */}
      <button
        className="md:hidden fixed top-4 left-4 z-50 p-2 bg-[#3864F2] text-white rounded-md"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        {isSidebarOpen ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
      </button>

      {/* Main Content */}
      <div className="flex-1 p-8 bg-[#e4ebfe]">
        {/* نمایش صفحه فعال بر اساس گزینه انتخاب شده */}
        {activePage === "account" &&
          <div className="shadow-lg rounded-lg p-6 bg-white">
            <h1 className="text-3xl font-bold text-[#1C2FAF] mb-6">پنل کاربری: {userData.username}</h1>
            <div>
              <p className="text-xl mb-4"><strong>پسورد:</strong> *******</p>
              <p className="text-xl mb-4"><strong>سطح دسترسی:</strong> {userData.role}</p>
              <button
                onClick={handleLogout}
                className="mt-6 px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-all"
              >
                خروج
              </button>
            </div>
          </div>}
        {activePage === "manageDates" && <ManageDates />}
        {activePage === "settings" && <AddTime />}
        {activePage === "reports" && <ChatboxEditor />}
        {activePage === "blog" && <BlogManager />}
        {activePage === "footer" && <FooterManager />}
        {activePage === "support" && <UserInfo />}
      </div>
    </div>
  );
};

export default Panel;