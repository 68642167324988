import React, { useState, useEffect } from "react";
import axios from "axios";

const BlogManager = () => {
  const [blogs, setBlogs] = useState([]);
  const [newBlog, setNewBlog] = useState("");
  const [editBlog, setEditBlog] = useState({ id: "", content: "" });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get("https://api.asmary.com/blog/api/blogs");
      setBlogs(response.data);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  const handleAddBlog = async () => {
    try {
      const response = await axios.post("https://api.asmary.com/blog/api/blogs", {
        id: Date.now().toString(),
        content: newBlog,
      });
      setBlogs([...blogs, response.data]);
      setNewBlog("");
    } catch (error) {
      console.error("Error adding blog:", error);
    }
  };

  const handleEditBlog = async () => {
    try {
      const response = await axios.put(`https://api.asmary.com/blog/api/blogs/${editBlog.id}`, {
        id: editBlog.id,
        content: editBlog.content,
      });
      setBlogs(blogs.map(blog => (blog.id === editBlog.id ? response.data : blog)));
      setEditBlog({ id: "", content: "" });
      setIsEditing(false);
    } catch (error) {
      console.error("Error editing blog:", error);
    }
  };

  const handleDeleteBlog = async (id) => {
    try {
      await axios.delete(`https://api.asmary.com/blog/api/blogs/${id}`);
      setBlogs(blogs.filter(blog => blog.id !== id));
    } catch (error) {
      console.error("Error deleting blog:", error);
    }
  };

  return (
    <div className="w-full mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-bold text-center mb-6">مدیریت بلاگ</h1>
      <div className="mb-4">
        <h2 className="text-xl font-semibold mb-2">اضافه کردن بلاگ جدید</h2>
        <input
          type="text"
          value={newBlog}
          onChange={(e) => setNewBlog(e.target.value)}
          placeholder="محتوای بلاگ را وارد کنید"
          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          onClick={handleAddBlog}
          className="mt-2 w-full bg-blue-500 text-white font-semibold py-2 rounded-md hover:bg-blue-600 transition duration-200"
        >
          اضافه کردن بلاگ
        </button>
      </div>

      <div>
        <h2 className="text-xl font-semibold mb-2">بلاگ‌ها</h2>
        {blogs.map(blog => (
          <div key={blog.id} className="flex flex-col md:flex-row justify-between items-center bg-gray-100 p-4 mb-2 rounded-md shadow-sm">
            <span className="text-gray-800 mb-2 md:mb-0">{blog.content}</span>
            <div className="flex space-x-2">
              <button
                onClick={() => {
                  setEditBlog({ id: blog.id, content: blog.content });
                  setIsEditing(true);
                }}
                className="bg-yellow-500 text-white font-semibold py-1 px-3 rounded-md hover:bg-yellow-600 transition duration-200"
              >
                ویرایش
              </button>
              <button
                onClick={() => handleDeleteBlog(blog.id)}
                className="bg-red-500 text-white font-semibold py-1 px-3 rounded-md hover:bg-red-600 transition duration-200"
              >
                حذف
              </button>
            </div>
          </div>
        ))}
      </div>

      {isEditing && (
        <div className="mt-4">
          <h2 className="text-xl font-semibold mb-2">ویرایش بلاگ</h2>
          <input
            type=" text"
            value={editBlog.content}
            onChange={(e) => setEditBlog({ ...editBlog, content: e.target.value })}
            placeholder="محتوای بلاگ را ویرایش کنید"
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            onClick={handleEditBlog}
            className="mt-2 w-full bg-blue-500 text-white font-semibold py-2 rounded-md hover:bg-blue-600 transition duration-200"
          >
            به‌روزرسانی بلاگ
          </button>
        </div>
      )}
    </div>
  );
};

export default BlogManager;