import React, { useEffect, useState } from "react";
import './Cursor.css';

const Cursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isPointerActive, setIsPointerActive] = useState(false);
  const [size, setSize] = useState(40); // مقدار اولیه سایز
  let lastPosition = { x: 0, y: 0 };
  let lastTime = Date.now();
  let idleTimeout;

  useEffect(() => {
    const handleMouseMove = (e) => {
      const currentTime = Date.now();
      const timeDiff = currentTime - lastTime;

      // محاسبه فاصله بین مکان قبلی و مکان فعلی
      const deltaX = e.clientX - lastPosition.x;
      const deltaY = e.clientY - lastPosition.y;
      const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

      // محاسبه سرعت (فاصله / زمان)
      const speed = distance / timeDiff;

      // تعیین سایز براساس سرعت حرکت موس (با محدودیت حداقل و حداکثر)
      const newSize = Math.min(Math.max(40, speed * 50), 200);
      setSize(newSize);

      // به‌روزرسانی موقعیت موس و زمان
      lastPosition = { x: e.clientX, y: e.clientY };
      lastTime = currentTime;

      setPosition({ x: e.clientX, y: e.clientY });

      // ریست کردن تایمر زمان بی‌حرکتی
      if (idleTimeout) {
        clearTimeout(idleTimeout);
      }

      // تنظیم تایمر برای بازگشت به سایز اولیه پس از ۵۰۰ میلی‌ثانیه
      idleTimeout = setTimeout(() => {
        setSize(40); // بازگشت به سایز اولیه
      }, 100);
    };

    const handlePointerCheck = (e) => {
      const element = document.elementFromPoint(e.clientX, e.clientY);
      if (element && getComputedStyle(element).cursor === "pointer") {
        setIsPointerActive(true);
      } else {
        setIsPointerActive(false);
      }
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mousemove", handlePointerCheck);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mousemove", handlePointerCheck);
      clearTimeout(idleTimeout); // پاک کردن تایمر در هنگام خروج
    };
  }, []);

  return (
    <>
      {/* dottt دقیقا زیر نشانگر موس قرار می‌گیرد */}
      <div
        className={`dottt ${isPointerActive ? "pointer-active" : ""}`}
        style={{
          left: `${position.x}px`,
          top: `${position.y}px`,
        }}
      ></div>

      {/* دایره بیرونی با تغییر سایز براساس سرعت */}
      <div
        className={`cursor-circle ${isPointerActive ? "pointer-active" : ""}`}
        style={{
          left: `${position.x}px`,
          top: `${position.y}px`,
          width: `${size}px`,
          height: `${size}px`,
        }}
      >
        {isPointerActive && <div className="inner-circle"></div>}
      </div>
    </>
  );
};

export default Cursor;
