import React, { useState, useEffect } from 'react';
import '../chat/chat.css';

const Comments = () => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [editCommentId, setEditCommentId] = useState(null);
  const [editCommentText, setEditCommentText] = useState('');
  const [commentSender, setCommentSender] = useState('user');

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await fetch('https://api.asmary.com/massegebox/messages');
        if (!response.ok) {
          throw new Error('Failed to fetch comments');
        }
        const data = await response.json();
        setComments(data);
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };

    fetchComments();
  }, []);

  const handleAddComment = async () => {
    if (!newComment) return;

    const commentData = { text: newComment, sender: commentSender };

    try {
      const response = await fetch('https://api.asmary.com/massegebox/messages', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(commentData),
      });
      const data = await response.json();
      setComments(data);
      setNewComment('');
      setCommentSender('user');
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleEditComment = async (id) => {
    if (!editCommentText) return;

    const updatedComment = { text: editCommentText };

    try {
      const response = await fetch(`https://api.asmary.com/massegebox/messages/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedComment),
      });
      const data = await response.json();
      setComments(data);
      setEditCommentId(null);
      setEditCommentText('');
    } catch (error) {
      console.error("Error editing comment:", error);
    }
  };

  const handleDeleteComment = async (id) => {
    try {
      const response = await fetch(`https://api.asmary.com/massegebox/messages/${id}`, {
        method: 'DELETE',
      });
      const data = await response.json();
      setComments(data);
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  return (
    <div className="mx-auto p-4 bg-gray-100 rounded-lg shadow-md w-full h-[100%] overflow-scroll" dir='ltr'>
      <h2 className="text-2xl font-bold text-gray-800 mb-4">Comments</h2>

      <div className="sticky top-0 bg-gray-100 z-10 shadow-md" dir="rtl">
        <input
          type="text"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Add a comment..."
          className="border border-gray-300 rounded-lg p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
        <select
          value={commentSender}
          onChange={(e) => setCommentSender(e.target.value)}
          className="border border-gray-300 rounded-lg p-2 mt-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          <option value="user">سمت مدیریت</option>
          <option value="other">سمت کاربر</option>
        </select>
        <button
          onClick={handleAddComment}
          className="bg-blue-500 hover:bg-blue-600 text-white rounded-lg p-2 mt-2 w-full transition"
        >
          Add Comment
        </button>
      </div>

      <div className="messages flex flex-col gap-4 mt-4">
        {comments.map((comment) => (
          <div
            key={comment.id}
            className={`message p-4 rounded-lg text-sm shadow-md ${comment.sender === 'user'
              ? 'bg-blue-500 text-white self-end'
              : 'bg-gray-200 text-gray-800 self-start'
              }`}
          >
            {editCommentId === comment.id ? (
              <>

                <input
                  type="text"
                  value={editCommentText}
                  onChange={(e) => setEditCommentText(e.target.value)}
                  className="border rounded p-2 w-full focus:outline-none text-[#000000] focus:ring-2 focus:ring-blue-400"
                />
                <div className="mt-2 flex flex-col space-y-2">
                  <button
                    onClick={() => handleEditComment(comment.id)}
                    className="bg-green-500 text-white rounded p-2 w-full"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => setEditCommentId(null)}
                    className="bg-gray-500 text-white rounded p-2 w-full"
                  >
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="flex justify-between items-center">
                  <p>{comment.text}</p>
                  <div className="flex flex-col space-y-2">
                    <button
                      onClick={() => {
                        setEditCommentId(comment.id);
                        setEditCommentText(comment.text);
                      }}
                      className="bg-yellow-500 text-white rounded px-2 py-1 w-full"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDeleteComment(comment.id)}
                      className="bg-red-500 text-white rounded px-2 py-1 w-full"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Comments;