import { useState, useEffect, useRef } from "react";
import { ReactTyped } from "react-typed";
import "./main.css";
import { NavLink } from "react-router-dom";
import BtnR from "../btnR/btnr";

const Main = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false); // مدیریت نمایش فرم

  useEffect(() => {
    if (isFormOpen) {
      document.body.style.overflow = 'hidden'; // جلوگیری از اسکرول
    } else {
      document.body.style.overflow = 'auto'; // برگرداندن حالت پیش‌فرض
    }

    // پاکسازی برای جلوگیری از خطا بعد از بسته شدن فرم
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isFormOpen]);

  useEffect(() => {
    // نمایش دکمه بدون بلور بعد از 1.5 ثانیه
    const timer = setTimeout(() => {
      setIsButtonVisible(true);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <>
      {/* افکت بلور برای زمانی که فرم باز است */}
      <div className={`relative ${isFormOpen ? "blur-sm fade-out" : ""} m-5 mt-10 md:m-10 `}>

        <div
          ref={sectionRef}
          className={`card_main pt-12 pb-12 main mb-[80px] max-w-[1200px] container justify-between flex flex-col md:flex-row justify-between items-center p-6 md:p-10 ${isVisible ? "animate-fade-in" : ""
            }`}
        >
          {/* متن انگلیسی */}
          <div className=" text hidden lg:block w-[44%] text-left h-[430]">
            <div className="h-[550px]">
              <p
                className=" text-2xl text-justify leading-loose "
                style={{ fontFamily: "IRANSansXMen", direction: "ltr", lineHeight: "3rem" }}
              >
                At Asmari, we blend expertise and dedication to deliver marketing and growth solutions tailored to your business. Trust us to handle your&nbsp;
                <span className="text-[#3764f2] "
                  style={{
                    display: "inline-block"
                    , width: "125px"
                  }}>
                  {isVisible && (
                    <ReactTyped
                      strings={["patients", "clients", "marketing", "website", "branding", "and more"]}
                      typeSpeed={50}
                      backSpeed={40}
                      backDelay={1500}
                      loop
                    />
                  )}
                </span>
                Specializing in healthcare, we craft smart growth plans for clinics to hospitals, providing websites, VoIP, networks, and integrated customer management systems to ensure your success.
              </p>
            </div>

            <br />
            <div className="h-[200px]">
              <p className="" style={{ fontFamily: "IRANSansXUen", direction: "ltr", lineHeight: "2rem", textAlign: "justify", fontSize: "1rem" }}>
                Our primary policy is to protect the interests of those who trust us, which is why we only accept a limited number of clients in each area and field. Before booking your consultation session, make sure to read this page thoroughly.
              </p>
            </div>
          </div>
          {/* متن فارسی */}
          <div className="text w-full lg:w-[44%] text-center lg:text-right  h-[430]">
            <div className="md:h-[300px] lg:h-[550px] ">
              <p
                className=" text-xl lg:text-2xl md:text-xl  sm: text-justify leading-loose "
                style={{ fontFamily: "IRANSansXL", lineHeight: "3rem" }}
              >
                در آسماری ما تجربه، دانش فنی، تخصص و البته تعهد مورد نیاز برای بازاریابی و توسعه کسب کار شما را یکجا جمع کرده ایم تا شما خیالتان بابت
                <strong className="text-[#3764f2]"
                  style={{
                    display: "inline-block"
                    , width: "125px",
                    textAlign: "center"
                  }}
                >
                  {isVisible && (
                    <ReactTyped
                      strings={["بیمار ", " مشتری ", "مارکتینگ", "وبسایت", "برندینگ", " تمام جزییات "]}
                      typeSpeed={50}
                      backSpeed={40}
                      backDelay={1500}
                      loop
                    />
                  )}
                </strong>
                <span>مرکز خود راحت باشد. آسماری بصورت تخصصی در حوزه سلامت از سطح یک مطب تا کلینیک برای طراحی هوشمندانه پلن رشد و تبلیغاتی، زیرساخت های لازم مانند وبسایت، ویپ و شبکه تا سیستم مدیریت بیماران و یکپارچه سازی در کنار شماست.</span>
              </p>
            </div>

            <br />
            <div className="h-[200px]">
              <p
                className=" text-justify"
                style={{ fontFamily: "IRANSansXL", lineHeight: "2rem", fontSize: "1rem" }}
              >
                مهمترین سیاست ما حفظ منافع کسانی است که به ما اعتماد میکنند (مشتریان آسماری) و به همین علت در هر حوزه و محدوده صرفا تعداد محدوی مشتری پذیرش میکنیم. پس از رزرو و قبل از جلسه مشاوره حتما این صفحه را تا انتها بخوانید.
              </p>
            </div>

          </div>
        </div>
        <div className="btnconect container  flex flex-col flex-center z-[0]">
          {/* <button
            onClick={() => setIsFormOpen(true)} // باز کردن فرم
            className={`bg-[#1C2FAF] text-white py-2 px-6 rounded-lg ${isButtonVisible ? "visible" : ""
              }`}
          >
            تماس با ما
          </button> */}
          <BtnR />

        </div>
      </div>



    </>
  );
};

export default Main;



