import React, { useEffect, useState } from "react";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
const EditFooter = () => {
  const [contactInfo, setContactInfo] = useState({});
  const [contact, setContact] = useState({});
  const [quickLinks, setQuickLinks] = useState([]);
  const [socialLinks, setSocialLinks] = useState({});

  useEffect(() => {
    const fetchFooterData = async () => {
      try {
        const response = await axios.get('https://api.asmary.com/footer/api/footer');
        setContactInfo(response.data.contactInfo);
        setContact(response.data.contact);
        setQuickLinks(response.data.quickLinks);
        setSocialLinks(response.data.socialLinks);
      } catch (error) {
        console.error("Error fetching footer data:", error);
      }
    };

    fetchFooterData();
  }, []);

  const handleUpdate = async () => {
    const updatedData = {
      contactInfo,
      contact,
      quickLinks,
      socialLinks,
    };

    try {
      await axios.put('https://api.asmary.com/footer/api/footer', updatedData);
      toast.success("تغییرات با موفقیت اعمال شد.");
    } catch (error) {
      console.error("Error updating footer data:", error);
    }
  };

  return (
    <div className="w-full h-[100%] overflow-scroll mx-auto p-6 bg-white rounded-lg shadow-md">
      <Toaster />
      <h2 className="text-2xl font-bold text-center mb-6">ویرایش فوتر</h2>
      <div className="mb-4">
        <h3 className="text-xl font-semibold mb-2">اطلاعات تماس</h3>
        <input
          type="email"
          value={contactInfo.email}
          onChange={(e) => setContactInfo({ ...contactInfo, email: e.target.value })}
          placeholder="ایمیل"
          className="w-full p-2 border border-gray-300 rounded-md mb-2"
        />
        <input
          type="text"
          value={contactInfo.phone}
          onChange={(e) => setContactInfo({ ...contactInfo, phone: e.target.value })}
          placeholder="تلفن"
          className="w-full p-2 border border-gray-300 rounded-md"
        />
      </div>
      <div className="mb-4">
        <h3 className="text-xl font-semibold mb-2">توضیحات فوتر</h3>
        <textarea
          value={contact.contactFooter}
          onChange={(e) => setContact({ ...contact, contactFooter: e.target.value })}
          placeholder="توضیحات فوتر"
          className="w-full p-2 border border-gray-300 rounded-md"
          rows="4"
        />
      </div>
      <div className="mb-4">
        <h3 className="text-xl font-semibold mb-2">لینک‌های سریع</h3>
        {quickLinks.map((link, index) => (
          <div key={index} className="flex flex-col mb-2">
            <input
              type="text"
              value={link.name}
              onChange={(e) => {
                const newLinks = [...quickLinks];
                newLinks[index].name = e.target.value;
                setQuickLinks(newLinks);
              }}
              placeholder="نام لینک"
              className="w-full p-2 border border-gray-300 rounded-md mb-1"
            />
            <input
              type="text"
              value={link.url}
              onChange={(e) => {
                const newLinks = [...quickLinks];
                newLinks[index].url = e.target.value;
                setQuickLinks(newLinks);
              }}
              placeholder="URL لینک"
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
        ))}
      </div>
      <div className="mb-4">
        <h3 className="text-xl font-semibold mb-2">لینک‌های اجتماعی</h3>
        <input
          type="text"
          value={socialLinks.facebook}
          onChange={(e) => setSocialLinks({ ...socialLinks, facebook: e.target.value })}
          placeholder="لینک فیس‌بوک"
          className="w-full p-2 border border-gray-300 rounded-md mb-2"
        />
        <input
          type="text"
          value={socialLinks.twitter}
          onChange={(e) => setSocialLinks({ ...socialLinks, twitter: e.target.value })}
          placeholder="لینک توییتر"
          className="w-full p-2 border border-gray-300 rounded-md mb-2"
        />
        <input
          type="text"
          value={socialLinks.linkedin}
          onChange={(e) => setSocialLinks({ ...socialLinks, linkedin: e.target.value })}
          placeholder="لینک لینکدین"
          className="w-full p-2 border border-gray-300 rounded-md"
        />
      </div>
      <button
        onClick={handleUpdate}
        className="w-full bg-blue-500 text-white font-bold py-2 rounded-md hover:bg-blue-600 transition duration-200"
      >
        ذخیره تغییرات
      </button>
    </div>
  );
};

export default EditFooter;