import React, { useState } from 'react';
import axios from 'axios';

function App() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otpCode, setOtpCode] = useState('');
  const [isVerified, setIsVerified] = useState(false);

  const handleSendOtp = async () => {
    try {
      const response = await axios.post('http://localhost:3000/sms/send-otp', { phoneNumber });
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post('http://localhost:3000/sms/verify-otp', { phoneNumber, otpCode });
      console.log(response.data);
      setIsVerified(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container mx-auto p-4 mt-4">
      <h1 className="text-3xl font-bold mb-4">احراز هویت</h1>
      <div className="flex flex-col">
        <input
          type="text"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder="شماره تلفن"
          className="w-full p-2 mb-4 border border-gray-400 rounded"
        />
        <button
          onClick={handleSendOtp}
          className="w-full p-2 mb-4 bg-blue-500 text-white rounded hover:bg-blue-700"
        >
          ارسال کد
        </button>
        {isVerified ? (
          <div className="text-green-500">احراز هویت موفق بود</div>
        ) : (
          <div>
            <input
              type="text"
              value={otpCode}
              onChange={(e) => setOtpCode(e.target.value)}
              placeholder="کد احراز هویت"
              className="w-full p-2 mb-4 border border-gray-400 rounded"
            />
            <button
              onClick={handleVerifyOtp}
              className="w-full p-2 mb-4 bg-blue-500 text-white rounded hover:bg-blue-700"
            >
              تایید کد
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;