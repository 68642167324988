import React, { Component } from "react";
import "./blog.css";

class Admins extends Component {
  state = {
    blogs: []
  };

  componentDidMount() {
    fetch("https://api.asmary.com/blog/api/blogs") // URL API شما
      .then(response => response.json())
      .then(data => {
        this.setState({ blogs: data });
      })
      .catch(error => console.error("Error fetching data:", error));
  }

  render() {
    return (
      <div className="logos">
        <div className="logos-slide mt-[10px] md:mt-[100px]">
          {this.state.blogs.map((blog) => (
            <React.Fragment key={blog.id}>
              <div className="blog">{blog.content}</div>
              <span> → </span> {/* فلش بین بلاگ‌ها */}
            </React.Fragment>
          ))}
        </div>
        <div className="logos-slide mt-[10px] md:mt-[100px]">
          {this.state.blogs.map((blog) => (
            <React.Fragment key={blog.id}>
              <div className="blog">{blog.content}</div>
              <span> → </span> {/* فلش بین بلاگ‌ها */}
            </React.Fragment>
          ))}
        </div>
        <div className="logos-slide mt-[10px] md:mt-[100px]">
          {this.state.blogs.map((blog) => (
            <React.Fragment key={blog.id}>
              <div className="blog">{blog.content}</div>
              <span> → </span> {/* فلش بین بلاگ‌ها */}
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  }
}

export default Admins;