import React, { useState, useEffect } from "react";
import "./header.css";
import logo from './logo.png'
import logo2 from './asmariw.png'
const Header = () => {
  const [isFirstLogoVisible, setIsFirstLogoVisible] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHeaderExpanded, setIsHeaderExpanded] = useState(false); // حالت تغییر عرض هدر

  // تغییر وضعیت لوگوها بعد از 3 ثانیه
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsFirstLogoVisible(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  // مدیریت اسکرول
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1500) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // تغییر عرض هدر هنگام بارگذاری
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsHeaderExpanded(true); // بعد از 0.5 ثانیه عرض بزرگ شود
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {/* <div
        className="flex items-center justify-between h-[28px] bg-[#e1e8f9] z-10 transition-all duration-700 ease-in-out"
        style={{
          position: 'fixed',
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          width: '37%',
        }}
      ></div> */}

      <header
        className={`card_main_header fixed top-7  px-6 py-2 flex items-center justify-between z-20 bg-[#1C2FAF] transition-all duration-700 ease-in-out ${isHeaderExpanded
          ? isScrolled
            ? "left-9 right-9 sm:left-20 sm:right-20 md:left-[180px] md:right-[180px] lg:left-[370px] lg:right-[370px] xl:left-[440px] xl:right-[440px] 2xl:left-[490px] 2xl:right-[490px]"
            : "left-4 right-4 sm:left-12 sm:right-12 md:left-24 md:right-24 lg:left-32 lg:right-32 xl:left-28 xl:right-28 2xl:left-20 2xl:right-20"
          : "left-[40%] right-[40%]"}`} /* عرض کوچک اولیه */
      >
        {/* لوگو سمت چپ */}
        <div className="z-30 text-xl font-bold text-white relative w-[140px] h-[40px]">
          {/* لوگوی اول */}
          <img
            src={logo}
            alt="logo1"
            className={`logo1 absolute w-[40px] h-auto transition-all duration-1000 ${isFirstLogoVisible ? "animate-logo-fade-in" : "animate-logo-fade-out"
              }`}
          />

          {/* لوگوی دوم */}
          <img
            src={logo2}
            alt="logo2"
            className={`logo2 w-[100px] ${isFirstLogoVisible ? "opacity-0" : "animate-logo-fade-in"
              }`}
          />
        </div>

        {/* گزینه‌های وسط */}
        {/* <div className="flex gap-6 text-white md:flex hidden">
        <button className="hover:text-blue-500 transition">گزینه ۱</button>
        <button className="hover:text-blue-500 transition">گزینه ۲</button>
      </div> */}

        {/* منوی همبرگری سمت راست */}
        <div className="relative z-30">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className={`relative p-2 rounded-full transition duration-200 ${isMenuOpen
              ? " bg-black text-white"
              : "bg-transparent text-white hover:text-blue-500"
              }`}
          >
            {/* آیکون همبرگری */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>

        {/* منوی کشویی */}
        <div
          className={`absolute left-0 top-[-30px] rounded-b-xl rounded[100px] right-0 bg-[#1C2FAF] h-[500px] z-10 transition-transform duration-200 ease-in-out ${isMenuOpen ? "translate-y-0 " : "-translate-y-full"
            } flex justify-center items-center`}
        >
          <ul className="flex flex-col gap-4 text-white">
            <li>
              <button className="hover:text-blue-500 transition">منو ۱</button>
            </li>
            <li>
              <button className="hover:text-blue-500 transition">منو ۲</button>
            </li>
            <li>
              <button className="hover:text-blue-500 transition">منو ۳</button>
            </li>
          </ul>
        </div>
      </header>
    </>

  );
};

export default Header;

