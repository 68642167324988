import React, { useState, useEffect, useRef } from "react";
import moment from "moment-jalaali";
import axios from "axios";
import "moment/locale/fa";
import './reservation.css';
import toast, { Toaster } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faTrash } from '@fortawesome/free-solid-svg-icons';
const PersianCalendar = () => {
  const [currentDate, setCurrentDate] = useState(moment());
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [specialDates, setSpecialDates] = useState([]);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [loadingTimes, setLoadingTimes] = useState(false);
  const [message, setMessage] = useState("");
  const [submitMessage, setSubmitMessage] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false); // حالت جدید برای نمایش باکس تأیید
  const [showConfirmationCode, setShowConfirmationCode] = useState(false);
  const [datesWithoutTimes, setDatesWithoutTimes] = useState([]);
  const [isVerified, setIsVerified] = useState(false);
  const [otpCode, setOtpCode] = useState("");
  const timeSelectionRef = useRef(null);
  const [showFinalMessage, setShowFinalMessage] = useState(false);
  useEffect(() => {
    const fetchSpecialDates = async () => {
      try {
        const response = await axios.get("https://api.asmary.com/availableDates");
        const fetchedDates = response.data;

        // فیلتر کردن تاریخ‌ها برای فقط تاریخ‌هایی که زمان دارند
        const filteredDates = fetchedDates.filter(item => item.times.length > 0);
        const convertedDates = filteredDates.map((item) => item.date);

        // تبدیل تاریخ‌ها به فرمت مناسب
        const formattedDates = convertedDates.map((date) =>
          moment(date, "jYYYY-jMM-jDD").format("jYYYY-jMM-jDD")
        );

        setSpecialDates(formattedDates);
      } catch (error) {
        console.error("خطا در بارگذاری تاریخ‌ها:", error);
        setMessage("خطا در بارگذاری تاریخ‌ها.");
      }
    };

    fetchSpecialDates();
  }, []);

  useEffect(() => {
    if (selectedDate) {
      setLoadingTimes(true);
      const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
      axios
        .get(`https://api.asmary.com/availableDates/times/${formattedDate}`)
        .then((response) => {
          setAvailableTimes(response.data.times || []);
          setMessage("");
        })
        .catch((error) => {
          console.error("Error fetching times for selected date", error);
          setMessage("خطا در بارگذاری تایم‌ها.");
        })
        .finally(() => {
          setLoadingTimes(false);
        });
    }
  }, [selectedDate]);


  const goToNextMonth = () =>
    setCurrentDate(currentDate.clone().add(1, "month"));
  const goToPreviousMonth = () =>
    setCurrentDate(currentDate.clone().subtract(1, "month"));

  const daysOfWeek = ["ش", "ی", "د", "س", "چ", "پ", "ج"];
  const startOfMonth = currentDate.clone().startOf("jMonth");
  const endOfMonth = currentDate.clone().endOf("jMonth");
  const daysInMonth = Array.from(
    { length: endOfMonth.jDate() },
    (_, i) => startOfMonth.clone().add(i, "day")
  );

  const emptyDays = Array.from({ length: startOfMonth.day() });

  const handleDayClick = (day) => {
    const formattedDay = day.format("jYYYY-jMM-jDD");

    if (specialDates.includes(formattedDay)) {
      setSelectedDate(formattedDay);

      // بارگذاری زمان‌ها
      setLoadingTimes(true);

      const formattedDate = moment(formattedDay).format("YYYY-MM-DD");

      axios
        .get(`https://api.asmary.com/availableDates/times/${formattedDate}`)
        .then((response) => {
          if (response.data.times && response.data.times.length > 0) {
            setAvailableTimes(response.data.times);
            setMessage("");
          } else {
            setAvailableTimes([]);
            toast.error("برای این تاریخ زمانی در دسترس نیست !");
            setDatesWithoutTimes((prev) => [...prev, formattedDay]); // اضافه کردن تاریخ بدون تایم
          }
        })
        .catch((error) => {
          console.error("Error fetching times for selected date", error);
          setMessage("خطا در بارگذاری تایم‌ها.");
        })
        .finally(() => {
          setLoadingTimes(false);
        });
    } else {
      toast.error("فقط می‌توانید روزهای خاص را انتخاب کنید");
    }
  };


  const handleTimeSelect = (timeObj) => {
    if (!timeObj.booked) {
      setSelectedTimes([timeObj]); // فقط یک زمان انتخاب شود
    }
  };

  const handleTimeRemove = (time) => {
    setSelectedTimes(selectedTimes.filter((t) => t.time !== time.time));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleSubmit = () => {
    if (selectedTimes.length === 0) {
      toast.error("لطفاً زمانی را انتخاب کنید.");
      return;
    }
    setShowConfirmation(true); // نمایش باکس تأیید
  };

  const [phoneNumber, setPhoneNumber] = useState('');
  const [userInfo, setUserInfo] = useState({ name: "", phone: "" });

  const handleSendOtp = async () => {
    if (!userInfo.name || !userInfo.phone) {
      toast.error("لطفاً نام و شماره تماس خود را وارد کنید."); // نمایش هشدار
      return; // خروج از تابع
    }
    try {
      const response = await axios.post('https://api.asmary.com/sms/send-otp', { phoneNumber });
      console.log(response.data);
      setUserInfo({ ...userInfo, phone: phoneNumber }); // اضافه کردن شماره تلفن به userInfo

      // نمایش پیام هشدار پس از ارسال کد OTP
      toast.success("کد تأیید به شماره تلفن شما ارسال شد.");
    } catch (error) {
      console.error(error);
      toast.error("خطا در ارسال کد تأیید. لطفاً دوباره تلاش کنید."); // نمایش پیام خطا در صورت بروز مشکل
    }
  };

  const handleVerifyOtp = async () => {
    try {
      // تأیید کد OTP
      const response = await axios.post('https://api.asmary.com/sms/verify-otp', { phoneNumber, otpCode });
      console.log(response.data);
      setIsVerified(true); // تغییر وضعیت به تأیید شده

      // اگر تأیید موفقیت‌آمیز بود، اطلاعات را ثبت کنید
      const payload = {
        date: selectedDate,
        times: selectedTimes,
        user: userInfo,
      };

      // مرحله اول: ارسال درخواست برای ثبت رزرو
      await axios.post("https://api.asmary.com/userinfo/submitReservation", payload);

      // مرحله دوم: ارسال درخواست PUT برای به‌روزرسانی booked به true
      const updatePayload = {
        times: selectedTimes.map(time => ({ time: time.time, booked: true })),
      };
      await axios.put(`https://api.asmary.com/availableDates/update-booking/${selectedDate}`, updatePayload);

      // به‌روزرسانی وضعیت زمان‌ها در حالت محلی
      setAvailableTimes(prevTimes =>
        prevTimes.map(time =>
          selectedTimes.some(selected => selected.time === time.time)
            ? { ...time, booked: true } // تغییر وضعیت به booked: true
            : time
        )
      );

      // بستن باکس تأیید و نمایش پیام نهایی
      setShowConfirmation(false); // بستن باکس تأیید
      setShowFinalMessage(true); // نمایش پیام نهایی
      setSubmitMessage("رزرو با موفقیت ثبت شد!");
      toast.success("رزرو با موفقیت ثبت شد!");
      setSelectedTimes([]);
      setUserInfo({ name: "", phone: "" });

    } catch (error) {
      console.error("Error verifying OTP or submitting reservation:", error);
      if (error.response && error.response.data) {
        toast.error(error.response.data.error || "خطا در تأیید کد.");
      } else {
        setSubmitMessage("خطا در ثبت رزرو.");
      }
    }
  };



  return (
    <div className="flex items-center justify-center flex-col  md:flex-row   gap-4 max-w-6xl mx-auto rounded-lg mt-[60px] pb-[100px] md:pb-[300px]" style={{ paddingTop: "20px", direction: "rtl" }}>
      <Toaster />



      {/* ستون تقویم */}
      <div className="cart_calendar" >
        <div className="neon-box cart_calendarf bg-[#fff] p-4 rounded-lg overflow-hidden" >
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={goToPreviousMonth}
              className="btncalender"
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
            <h2 className="text-lg font-bold">
              {currentDate.format("jMMMM jYYYY")}
            </h2>
            <button
              onClick={goToNextMonth}
              className="btncalender"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
          </div>

          <div className="grid grid-cols-7 text-center text-gray-600 mb-2">
            {daysOfWeek.map((day, index) => (
              <div key={index} className="font-medium">
                {day}
              </div>
            ))}
          </div>

          <div className="grid grid-cols-7 gap-1">
            {emptyDays.map((_, index) => (
              <div key={index}></div>
            ))}
            {daysInMonth.map((day, index) => {
              const formattedDay = day.format("jYYYY-jMM-jDD");
              const isSpecial = specialDates.includes(formattedDay);
              const isWithoutTime = datesWithoutTimes.includes(formattedDay); // بررسی تاریخ‌های بدون تایم
              const isSelected = formattedDay === selectedDate; // بررسی تاریخ انتخاب‌شده

              // بررسی اینکه آیا تاریخ قبل از تاریخ امروز است
              const isBeforeToday = day.isBefore(moment(), "day");

              // اگر تاریخ خاص و قبل از امروز باشد، آن را نمایش ندهید
              if (isSpecial && isBeforeToday) {
                return null; // یا می‌توانید یک عنصر خالی برگردانید
              }

              return (
                <div
                  key={index}
                  onClick={() => handleDayClick(day)}
                  className={`p-2 text-center rounded-lg cursor-pointer transition-colors ${isSelected
                    ? "bg-[#1C2FAF] text-white" // تاریخ انتخاب‌شده
                    : isWithoutTime
                      ? "bg-red-100 border-2 border-red-400 text-red-800" // تاریخ بدون تایم
                      : isSpecial
                        ? "bg-green-100 border-2 border-green-400 text-green-800" // تاریخ خاص
                        : day.isSame(moment(), "day")
                          ? "border-2 border-[#1C2FAF] text-black" // تاریخ امروز
                          : "hover:bg-gray-100" // حالت معمولی
                    }`}
                >
                  {day.jDate()}
                </div>
              );
            })}

          </div>
        </div>
      </div>

      {/* ستون انتخاب زمان */}
      <div ref={timeSelectionRef} className="cart_calendar  overflow-hidden">
        <div className="cart_calendarf bg-[#fff] p-4 rounded-lg">
          <h3 className="text-lg text-center font-bold mb-4">انتخاب زمان</h3>
          {selectedDate ? (
            <div>
              <h4 className="text-sm font-bold mb-2 text-right mb-3 ">
                زمان‌های موجود برای تاریخ : &nbsp;{moment(selectedDate).format("YYYY/MM/DD")}
              </h4>
              {loadingTimes ? (
                <p>در حال بارگذاری...</p>
              ) : (
                <ul>
                  {availableTimes.length > 0 ? (
                    availableTimes.map((timeObj, index) => (
                      <li
                        key={index}
                        dir="rtl"
                        className={`items-center justify-center mb-2 p-2 text-right rounded cursor-pointer 
                              ${timeObj.booked ? 'bg-red-200' : 'bg-gray-100 hover:bg-gray-200'}`} // استایل برای زمان‌های رزرو شده
                        onClick={() => !timeObj.booked && handleTimeSelect(timeObj)}
                      >
                        {timeObj.booked ? (
                          `${timeObj.time} (رزرو شده)`
                        ) : (
                          `${timeObj.time} (مدت‌زمان: ${timeObj.duration} دقیقه)`
                        )}
                      </li>
                    ))
                  ) : (
                    <p>برای این تاریخ هیچ زمانی موجود نیست.</p>
                  )}
                </ul>
              )}
            </div>
          ) : (
            <p>لطفاً یک تاریخ خاص را انتخاب کنید.</p>
          )}
          <div className="mt-4" dir="rtl">
            <h4 className="text-sm font-bold mb-4">زمان‌های انتخاب‌شده : </h4>
            <ul>
              {selectedTimes.map((timeObj, index) => (
                <li
                  key={index}
                  className="flex items-center justify-between mb-2 p-2 bg-gray-200 rounded"
                >
                  <span>{timeObj.time} (مدت‌زمان: {timeObj.duration} دقیقه)</span>
                  <button
                    onClick={() => handleTimeRemove(timeObj)} // استفاده از تابع حذف
                    className="text-red-500 hover:text-red-700"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <button onClick={handleSubmit} className="bntform_calender w-full mt-4 ">
            مرحله بعد
          </button>
        </div>
      </div>

      {/* ستون اطلاعات کاربر */}

      <div className="cart_calendar hidden lg:flex overflow-hidden">
        <div className="cart_calendarf bg-[#fff] z-2 p-4 rounded-lg text-center">
          <h3 className="text-lg font-bold mb-4">اطلاعات سایت</h3>
          <p className="mb-3"> نام شرکت: آسماری</p>
          <p className="mb-3">ایمیل: asmaryhub@gmail.com</p>
          <p>شماره تماس: 02191014189</p>
        </div>
      </div>


      {/* باکس تأیید رزرو */}
      {showConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="blur-background fixed inset-0 z-[-1]"></div>

          <br />
          <div className="flex flex-col items-center bg-white p-8 rounded-lg m-3 w-full md:w-1/2 lg:w-1/3 xl:w-2/4 h-4/5 overflow-scroll">
            <h1 className="text-3xl font-bold mb-4 text-2xl font-semibold text-[#1c2faf] ">احراز هویت</h1>
            <div className="flex flex-col items-center justify-center">
              <p>تاریخ: &nbsp; <span dir="rtl" className="text-[#1c2faf]"> {selectedDate}</span></p>
              <div className="mt-4 ">
                {selectedTimes.map((timeObj, index) => (
                  <p key={index} className="text-sm font-bold">زمان‌ انتخاب‌ شده: &nbsp; <span className="text-[#1c2faf]">{timeObj.time} (مدت‌زمان: {timeObj.duration} دقیقه)</span>
                  </p>
                ))}
              </div>
              <div className="mt-4 w-full" dir="rtl">
                <label htmlFor="name" className="block text-sm font-medium mb-3"> نام و نام خانوادگی‌:</label>
                <input
                  type="text"
                  name="name"
                  autoComplete="off"
                  value={userInfo.name}
                  onChange={handleInputChange}
                  placeholder=""
                  className="inputform_calender w-full"
                />
              </div>
              <div className="mt-4 w-full" dir="rtl">
                <label htmlFor="phone" className="block text-sm font-medium mb-3">شماره تماس:</label>
                <input
                  type="text"
                  name="phone"
                  autoComplete="off"
                  placeholder="شماره تلفن"
                  value={phoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                    setUserInfo({ ...userInfo, phone: e.target.value });
                  }}
                  className="inputform_calender w-full"
                />
              </div>
              <button
                onClick={handleSendOtp}
                className="bntform_calender mt-4 mb-4 w-1/2 m-2 py-2 px-4 rounded"
              >
                ارسال کد
              </button>

              <div className="flex flex-col items-center justify-center w-full">
                <input
                  type="text"
                  value={otpCode}
                  onChange={(e) => setOtpCode(e.target.value)}
                  placeholder="کد احراز هویت"
                  className="inputform_calender w-full"
                />
                <button
                  onClick={handleVerifyOtp}
                  className="bntform_calender mt-4 w-1/2 m-2 py-2 px-4 rounded"
                >
                  تایید کد
                </button>
                <button
                  onClick={() => setShowConfirmation(false)}
                  className="bntform_calender2 mt-4 w-1/2  m-2 py-2 px-4 rounded "
                >
                  بستن
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showFinalMessage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="blur-background fixed inset-0 z-[-1]"></div>
          <div className="flex flex-col items-center justify-center bg-white p-8 rounded-lg w-1/2">
            <h2 className="text-lg font-bold">رزرو شما با موفقیت ثبت شد!</h2>
            <p >تاریخ رزرو: <span dir="ltr">{selectedDate}</span></p>
            <button onClick={() => setShowFinalMessage(false)} className="bntform_calender mt-4">
              بستن
            </button>
          </div>
        </div>
      )}
    </div >
  );
};

export default PersianCalendar; 