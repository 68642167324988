import React, { useEffect, useState } from 'react';
import './baner.css';

const Banner = () => {
  const text1 = " Available Dec 2024 - Book a Meeting Now ";
  const text2 = " مشاوره خود را رزرو کنید  ";
  const [showText, setShowText] = useState(false); // کنترل نمایش متن‌ها

  useEffect(() => {
    // بعد از یک تأخیر کوتاه، متن‌ها واضح می‌شوند
    const timer = setTimeout(() => setShowText(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`banner-container ${showText ? "show-text" : ""}`}>
      <div className="octagon-banner1">
        <div className="scrolling-text-container">
          {/* محتوای تکراری برای seamless scroll */}
          {[...Array(35)].map((_, i) => (
            <React.Fragment key={`group1-${i}`}>
              <span className="banner-text">{text2}</span>
              <span className='mr-4 ml-4'>-</span>
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="octagon-banner2">
        <div className="scrolling-text-container">
          {[...Array(30)].map((_, i) => (
            <React.Fragment key={`group2-${i}`}>
              <span className="banner-text">{text1}</span>
              <span className='mr-4 ml-4'>-</span>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Banner;
