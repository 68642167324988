import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './captcha.css'
const Login = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [captchaText, setCaptchaText] = useState("");
  const [userInput, setUserInput] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [error, setError] = useState("");
  const [loginMessage, setLoginMessage] = useState("");
  const navigate = useNavigate(); // استفاده از useNavigate

  // تولید کد کپچا تصادفی
  const generateCaptcha = () => {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let captcha = "";
    for (let i = 0; i < 6; i++) {
      captcha += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setCaptchaText(captcha);
  };

  useEffect(() => {
    generateCaptcha(); // تولید کپچا هنگام بارگذاری
  }, []);

  const handleVerify = async () => {
    // تبدیل کد کپچا و ورودی کاربر به حروف کوچک برای حذف حساسیت
    if (userInput.toLowerCase() === captchaText.toLowerCase()) {
      setIsVerified(true);
      setError("");

      try {
        const response = await fetch("https://api.asmary.com/admin", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username, password }),
        });

        const result = await response.json();

        if (response.ok && result.success) {
          setLoginMessage("ورود با موفقیت انجام شد!");
          localStorage.setItem("username", username); // ذخیره نام کاربری
          onLoginSuccess(username); // فراخوانی تابع ورود موفقیت‌آمیز

          // هدایت به پنل
          navigate(`/panel/${username}`);
        } else {
          setLoginMessage(result.message || "ورود ناموفق. لطفاً مجدداً تلاش کنید.");
        }
      } catch (error) {
        setLoginMessage("خطا در ارتباط با سرور. لطفاً بعداً تلاش کنید.");
      }
    } else {
      setIsVerified(false);
      setError("کد وارد شده اشتباه است. لطفاً دوباره تلاش کنید.");
      generateCaptcha(); // تولید دوباره کپچا
    }
  };


  return (
    <div className="flex flex-col items-center justify-center p-6 bg-gray-50 min-h-screen" dir="rtl">
      <h1 className="text-2xl font-bold text-blue-600 mb-4">فرم ورود</h1>

      <div className="w-full max-w-sm bg-white p-6 rounded-lg shadow-md">
        {/* فرم ورود */}
        <label className="block text-gray-700 font-medium mb-2">نام کاربری:</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="نام کاربری"
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        <label className="block text-gray-700 font-medium mb-2">رمز عبور:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="رمز عبور"
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        {/* نمایش کپچا */}
        <div className="mt-4 flex items-center justify-between">
          <div
            className="font-mono text-xl text-blue-700 bg-gray-200 px-4 py-2 rounded-md no-select"
            style={{ letterSpacing: "3px" }}
          >
            {captchaText}
          </div>
          <button
            onClick={generateCaptcha}
            className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
          >
            تغییر کپچا
          </button>
        </div>

        <label className="block text-gray-700 font-medium mt-4 mb-2">کپچا:</label>
        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder="کپچا را وارد کنید"
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        {error && <p className="text-red-500 mt-2">{error}</p>}
        {isVerified && <p className="text-green-500 mt-2">کپچا تأیید شد!</p>}

        {loginMessage && (
          <p className={`mt-4 ${loginMessage.includes("موفقیت") ? "text-green-500" : "text-red-500"}`}>
            {loginMessage}
          </p>
        )}

        <button
          onClick={handleVerify}
          className="w-full bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
        >
          ورود
        </button>
      </div>
    </div>
  );
};

export default Login;
