import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

const DateSelector = () => {
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [time, setTime] = useState('');
  const [duration, setDuration] = useState('');
  const [message, setMessage] = useState('');
  const [availableTimes, setAvailableTimes] = useState([]);

  // دریافت تاریخ‌ها از سرور
  useEffect(() => {
    axios.get('https://api.asmary.com/availableDates')
      .then((response) => {
        setDates(response.data);
      })
      .catch((error) => {
        console.error("Error fetching dates", error);
        setMessage("خطا در بارگذاری تاریخ‌ها");
      });
  }, []);

  // دریافت تایم‌های مربوط به تاریخ انتخاب شده
  useEffect(() => {
    if (selectedDate) {
      axios.get(`https://api.asmary.com/availableDates/times/${selectedDate}`)
        .then((response) => {
          setAvailableTimes(response.data.times || []);
        })
        .catch((error) => {
          console.error("Error fetching times for selected date", error);
          setMessage("خطا در بارگذاری تایم‌ها");
        });
    }
  }, [selectedDate]);

  // تابع برای بررسی تکراری بودن تایم
  const isTimeDuplicate = (newTime) => {
    return availableTimes.some((timeObj) => timeObj.time === newTime);
  };

  // ارسال زمان و مدت زمان برای تاریخ انتخاب شده
  const handleSubmit = () => {
    if (!time || !duration) {
      setMessage("لطفاً تایم و مدت زمان را وارد کنید.");
      return;
    }

    if (isTimeDuplicate(time)) {
      setMessage("این تایم قبلاً انتخاب شده است.");
      return;
    }

    const updatedTimes = [
      {
        time,
        duration,
        booked: false,
      },
    ];

    axios.post('https://api.asmary.com/availableDates/update', { date: selectedDate, times: updatedTimes })
      .then((response) => {
        toast.success("تایم با موفقیت اضافه شد."); // نمایش پیام موفقیت
        setMessage(response.data.message);
        setTime('');
        setDuration('');
        setAvailableTimes([...availableTimes, { time, duration, booked: false }]);
      })
      .catch((error) => {
        console.error("Error updating times", error);
        setMessage("خطا در بروزرسانی زمان‌ها");
      });
  };

  // حذف تایم از سرور و بروزرسانی لیست
  const handleDeleteTime = (timeToDelete) => {
    axios.delete(`https://api.asmary.com/availableDates/delete-time/${selectedDate}`, {
      data: {
        time: timeToDelete,
      },
    })
      .then((response) => {
        toast.success("تایم با موفقیت حذف شد."); // نمایش پیام موفقیت
        setMessage(response.data.message);
        setAvailableTimes(availableTimes.filter((timeObj) => timeObj.time !== timeToDelete));
      })
      .catch((error) => {
        console.error("Error deleting time", error);
        setMessage("خطا در حذف زمان");
      });
  };

  const toggleBookingStatus = (timeToToggle, newStatus) => {
    if (!timeToToggle || typeof newStatus !== 'boolean') {
      setMessage("داده‌های نامعتبر.");
      return;
    }

    const updatedTimes = [
      {
        time: timeToToggle,
        booked: newStatus,
      },
    ];

    axios.put(`https://api.asmary.com/availableDates/update-booking/${selectedDate}`, {
      times: updatedTimes,
    })
      .then((response) => {
        setMessage(response.data.message || "تغییر وضعیت با موفقیت انجام شد.");
        setAvailableTimes(availableTimes.map((timeObj) =>
          timeObj.time === timeToToggle ? { ...timeObj, booked: newStatus } : timeObj
        ));
      })
      .catch((error) => {
        console.error("Error updating booking status", error.response?.data || error.message);
        setMessage("خطا در تغییر وضعیت رزرو");
      });
  };

  return (
    <div className="mt-8 p-6 bg-white shadow-lg rounded-lg max-full mx-auto">
      <Toaster /> {/* نمایش توستر برای پیام‌ها */}
      <h1 className="text-3xl font-semibold text-center text-gray-700 mb-6">انتخاب تاریخ و زمان</h1>

      {/* نمایش تاریخ‌های موجود */}
      <div className="mb-4">
        <label htmlFor="date" className="block text-lg font-medium text-gray-600">تاریخ را انتخاب کنید:</label>
        <select
          id="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          className="mt-2 p-3 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">تاریخ را انتخاب کنید</option>
          {dates.map((date) => (
            <option key={date.date} value={date.date}>
              {date.date}
            </option>
          ))}
        </select>
      </div>

      {/* نمایش تایم‌های قبلی برای تاریخ انتخاب شده */}
      {selectedDate && availableTimes.length > 0 && (
        <div className="mb-6">
          <h2 className="text-xl font-medium text-gray-700 mb-4" dir='rtl'>تایم‌های موجود برای تاریخ {selectedDate}:</h2>
          <ul className="space-y-2">
            {availableTimes.map((timeObj) => (
              <li key={timeObj.time} className="flex justify-between items-center p-3 border border-gray-300 rounded-md">
                <span className="text-gray-600">
                  {timeObj.time} - {timeObj.duration} دقیقه - {timeObj.booked ? 'رزرو شده' : 'آزاد'}
                </span>
                <div className="flex space-x-4">
                  <button
                    onClick={() => toggleBookingStatus(timeObj.time, !timeObj.booked)}
                    className={`m-3 text-white font-semibold py-1 px-3 rounded ${timeObj.booked ? 'bg-green-500 hover:bg-green-600' : 'bg-gray-500 hover:bg-gray-600'}`}
                  >
                    {timeObj.booked ? 'لغو رزرو' : 'رزرو'}
                  </button>
                  <button
                    onClick={() => handleDeleteTime(timeObj.time)}
                    className="text-red-500 hover:text-red-700 font-semibold"
                  >
                    حذف
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* فرم تایم و مدت زمان */}
      {selectedDate && (
        <div>
          <div className="mb-4">
            <label htmlFor="time" className="block text-lg font-medium text-gray-600">زمان:</label>
            <input
              id="time"
              type="time"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              className="mt-2 p-3 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="mb-6">
            <label htmlFor="duration" className="block text-lg font-medium text-gray-600">مدت زمان (دقیقه):</label>
            <input
              id="duration"
              type="number"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              className="mt-2 p-3 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <button
            onClick={handleSubmit}
            className="w-full py-3 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
          >
            ارسال
          </button>
        </div>
      )}

      {/* نمایش پیام‌های وضعیت */}
      {message && (
        <p className="mt-4 text-center text-red-500 font-semibold">
          {message}
        </p>
      )}
    </div>
  );
};

export default DateSelector; 