import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Page, Text, View, Document, StyleSheet, Font, PDFDownloadLink } from '@react-pdf/renderer';

// بارگذاری فونت‌های فارسی
Font.register({
  family: 'IRANSansX',
  src: '/fonts/woff/IRANSansX-Regular.woff',
});
Font.register({
  family: 'IRANSansX-Bold',
  src: '/fonts/woff/IRANSansX-Bold.woff',
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 30,
    fontFamily: 'IRANSansX',
    textAlign: "center"
  },
  title: {
    fontSize: 20,
    fontFamily: 'IRANSansX-Bold',
    marginBottom: 20,
  },
  table: {
    display: 'table',
    width: 'auto',
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCell: {
    border: '1px solid #000',
    padding: 5,
    fontSize: 12,
    fontFamily: 'IRANSansX',
    flex: 1,
  },
  header: {
    backgroundColor: '#f0f0f0',
    fontFamily: 'IRANSansX-Bold',
  },
});

const PDFDocument = ({ reservations }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.title}>گزارش اطلاعات مشتریان</Text>
      <View style={styles.table}>
        <View style={[styles.tableRow, styles.header]}>
          <Text style={styles.tableCell}>تاریخ</Text>
          <Text style={styles.tableCell}>زمان</Text>
          <Text style={styles.tableCell}>کاربر</Text>
        </View>
        {reservations.map((reservation, index) => (
          <View key={index} style={styles.tableRow}>
            <Text style={styles.tableCell}>{reservation.date}</Text>
            <Text style={styles.tableCell}>
              {reservation.times.map(time => `${time.time} (مدت‌زمان: ${time.duration} دقیقه)`).join(', ')}
            </Text>
            <Text style={styles.tableCell}>
              {reservation.user.name} - {reservation.user.phone}
            </Text>
          </View>
        ))}
      </View>
    </Page>
  </Document>
);

const AdminReservations = () => {
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReservations = async () => {
      try {
        const response = await axios.get('https://api.asmary.com/userinfo/reservations');
        setReservations(response.data);
      } catch (err) {
        setError('خطا در بارگذاری رزروها.');
      } finally {
        setLoading(false);
      }
    };

    fetchReservations();
  }, []);

  if (loading) {
    return <p className="text-center text-lg text-gray-700">در حال بارگذاری...</p>;
  }

  if (error) {
    return <p className="text-center text-lg text-red-500">{error}</p>;
  }

  return (
    <div className="container mx-auto p-5 text-center">
      <h2 className="text-2xl font-bold text-gray-800 mb-5">رزروهای ثبت‌شده</h2>
      {reservations.length === 0 ? (
        <p className="text-center text-lg text-gray-600">هیچ رز رو ثبت نشده است.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300 shadow-md rounded-lg">
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                <th className="border border-gray-300 p-3">تاریخ</th>
                <th className="border border-gray-300 p-3 ">زمان‌ها</th>
                <th className="border border-gray-300 p-3 ">کاربر</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {reservations.map((reservation, index) => (
                <tr key={index} className="border-b border-gray-300 hover:bg-gray-100">
                  <td className="border border-gray-300 p-3">{reservation.date}</td>
                  <td className="border border-gray-300 p-3">
                    {reservation.times.map((timeObj, timeIndex) => (
                      <span key={timeIndex}>
                        {timeObj.time} (مدت‌زمان: {timeObj.duration} دقیقه)
                        {timeIndex < reservation.times.length - 1 ? ', ' : ''}
                      </span>
                    ))}
                  </td>
                  <td className="border border-gray-300 p-3">
                    <span className="font-semibold">{reservation.user.name}</span> - {reservation.user.phone}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="mt-5 flex justify-end">
        <PDFDownloadLink document={<PDFDocument reservations={reservations} />} fileName="customer_info.pdf">
          {({ loading }) => (
            <button
              type="button"
              className="py-2 px-5 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300"
              disabled={loading}
            >
              {loading ? 'در حال تولید PDF...' : 'دانلود PDF'}
            </button>
          )}
        </PDFDownloadLink>
      </div>
    </div>
  );
};

export default AdminReservations;